<template>
  <div :class="$style.root">
    <h1 v-if="$route.meta.title" class="mb-4">{{title}}</h1>
    <div class="mb-4 clearfix">
      <VBtn depressed @click="$router.back()">
        <VIcon v-text="'mdi-arrow-left'" />
        Назад
      </VBtn>
    </div>
    <InspectorList
        :headers="headers"
        :items="list.items"
        :count="list.count"
        :page="page"
        :size="size"
        :loading="loading"
        @click:row="onDetailClick"
    />
  </div>
</template>

<script>
import {debounce, get, values, filter} from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';
import {APPLICATION_STATUSES as STATUSES, INSPECTOR_TYPES as TYPES, USER_TYPES} from '@/store/inspect/enums';
import InspectorList from '@/components/inspect/InspectorList/InspectorList';

export default {
  name: 'InspectorListView',
  components: {
    InspectorList,
  },
  props: {
    page: { type: Number, default: 1 },
    size: { type: Number, default: 20 },
    filter: { type: Object },

  },
  computed: {
    ...mapGetters({
      loading: 'inspect/pending',
      list: 'inspect/inspectors',
      hasRole: 'user/hasRole',
      user: 'user/current',
    }),
    statuses() {
      return values(STATUSES);
    },
    types() {
      return values(TYPES);
    },
    title() {
      const masterId = get(this.filter, 'masterId');
      return this.$route.meta.title + (masterId ? ` #${masterId}` : '');
    },
    isInspector() {
      return this.hasRole(get(USER_TYPES, 'INSPECTOR.value', 'INSPECTOR'), 'inspect');
    },
    headers() {
      const allHeaders = [
        { text: 'ID', value: 'id', sortable: false },
        { text: 'Имя', value: 'name', sortable: false },
        { text: 'Компания', value: 'company', sortable: false },
        { text: 'Вид инспектора', value: 'type.text', alias: 'type', options: this.types, sortable: false },
        { text: 'ИНН', value: 'inn', sortable: false },
        { text: 'Почта', value: 'email', sortable: false },
        { text: 'В работе', value: 'work', sortable: false },
        { text: 'Всего инспекций', value: 'inspections', sortable: false },
        { text: 'Рейтинг', value: 'rating', sortable: false },
        { text: 'Кол-во отзывов', value: 'rejects', sortable: false },
      ];
      return filter(allHeaders, (header) => this.isInspector && header.value !== 'rating' || !this.isInspector);
    }
  },
  methods: {
    ...mapActions({
      fetchInspectorList: 'inspect/fetchInspectorList',
    }),
    onUpdateState: debounce(function() {
      const { page, size, filter } = this;
      this.fetchInspectorList({ page, size, filter });
    }, 500),
    onDetailClick({id}) {
      const masterId = get(this.filter, 'masterId');
      if (masterId) {
        this.$router.push({ name: 'inspect/InspectorDetailView', params: { master: masterId, inspector: id }});
      } else {
        this.$router.push({ name: 'inspect/InspectorListView', params: { master: id }});
      }
    }
  },
  watch: {
    page: function() {
      this.onUpdateState();
    },
    size: function() {
      this.onUpdateState();
    },
    filter: {
      deep: true,
      handler: function() {
        this.onUpdateState();
      }
    }
  },
  mounted() {
    this.onUpdateState();
  }
}
</script>

<style module lang="scss">
.root {}
</style>
